import React from "react";
import MainLayout from "../componentsTailwind/MainLayout";
import { Helmet } from "react-helmet";
import MainVizualLP from "../components/MainVizualLP";
import SectionGrid from "../components/SectionGrid/SectionGrid";
import PhoneContactFormWrapper from "../components/LeadForms/PhoneContactFormWrapper";
import ContactForm from "../components/contactForm";
import { CONTRACT_SOURCE_ID_DUPLEX, ELECTRICITY } from "../constants";
import { graphql } from "gatsby";
import { getFullMediaLibraryURL } from "../helpers/mediaLibrary/mediaLibraryUtils";
import { SavingsCalculation } from "../components/SavingsCalculation/SavingsCalculation";
import { CALCULATION_COMMODITY_INDEX } from "../components/SavingsCalculation/hooks/use-calculation";
import { formatPrice } from "../formUtils";
import { OFFER_TYPE } from "../offers/offer-types";
import { getPageCalculatorFullProps } from "../calculator/page-calculator-prop-utils";

const BONUS_TEXT_KEY = "BONUS_AMOUNT";

const ElectricityBonusPage = props => {
  const { data } = props;

  const duplexPageData = data.mnd.duplexElectricity.data.attributes;

  // bonus
  const bonusAmount = duplexPageData.bonusAmount;

  const pageCalculator = duplexPageData.calculator;

  // intro banner
  const introBanner = duplexPageData.introBanner;
  const introBannerImageURL = `url(${getFullMediaLibraryURL(introBanner.image.data.attributes.url)})`;
  const { title: bannerTitle, text: bannerText, buttonTitle, buttonLink, footerText: bannerFooterText } = introBanner.bannerContent;

  const replaceBonusAmount = text => {
    return text.replace(`{{${BONUS_TEXT_KEY}}}`, formatPrice(bonusAmount));
  };

  // benefits grid
  let benefitsGridData = duplexPageData.benefitsGrid;
  benefitsGridData.sections = benefitsGridData.sections.map(section => {
    const transformedTitle = replaceBonusAmount(section.title);

    const transformedPoints = section.points.map(point => {
      return {
        ...point,
        text: replaceBonusAmount(point.text),
      };
    });

    return {
      ...section,
      points: transformedPoints,
      title: transformedTitle,
    };
  });

  // calculator
  const webCalculator = data.mnd.webCalculator.data.attributes;
  const offers = data.mnd.offer.data.attributes.offers.filter(offer => offer.offerAssignment === OFFER_TYPE.Duplex);

  return (
    <MainLayout>
      <Helmet>
        <title>Elektřina s bonusem | MND</title>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.mnd.cz/elektrina-s-bonusem"></link>
      </Helmet>

      <MainVizualLP
        title={bannerTitle}
        text={bannerText}
        backgroundImage={introBannerImageURL}
        buttonLink={buttonLink}
        buttonTitle={buttonTitle}
        customImage
        customStyle={{
          wrapper: {
            paddingTop: ["284px", null, 0],
            backgroundColor: ["white.100", null, "resourceGreenLighter"],
          },
          heading: {
            mb: ["16px", null, "24px"],
          },
          textWrapper: {
            zIndex: 2,
            px: "0px",
            paddingRight: ["0px", null, "22px", "22px"],
          },
          bannerImage: {
            backgroundPosition: "center bottom",
            backgroundSize: "contain",
            backgroundColor: "white.100",
            height: "254px",
            mx: "15px",
            marginTop: "30px",
          },
          boxContainer: {
            minHeight: [null, null, "396px"],
            px: ["20px", null, "15px"],
          },
        }}
        footerText={bannerFooterText}
      />

      <SectionGrid gridData={benefitsGridData} />

      <SavingsCalculation
        offers={offers}
        data={webCalculator}
        onResultChange={undefined}
        {...getPageCalculatorFullProps({
          pageCalculator,
          calculatorOptions: {
            bonus: {
              isUsingBonus: true,
              priceBonus: bonusAmount,
            },
            form: {
              noCommoditySwitch: true,
              locationRadio: {
                companyDisabled: true,
              },
            },
            result: {
              noResultTypeSwitch: true,
              hideUnderformPDF: true,
            },
            initialData: { selected: CALCULATION_COMMODITY_INDEX.ELECTRICITY },
          }
        })}
      />

      <PhoneContactFormWrapper>
        <ContactForm
          enableCallbackTime={true}
          enableEmail={false}
          type="PAGE_HP"
          commodity={ELECTRICITY}
          analytics={{
            type: "Lead",
            text: "Ozveme se vám",
            section: "Duplex",
            sourceId: CONTRACT_SOURCE_ID_DUPLEX,
          }}
          leadsource={CONTRACT_SOURCE_ID_DUPLEX}
        />
      </PhoneContactFormWrapper>
    </MainLayout>
  );
};

export const query = graphql`
  query ElectricityPage {
    mnd {
      duplexElectricity {
        data {
          attributes {
            bonusAmount

            # Intro banner component
            introBanner {
              bannerContent {
                title
                text
                buttonTitle
                buttonLink
                footerText
              }
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }

            # Benefits component
            benefitsGrid {
              title
              sections {
                id
                title
                points {
                  id
                  text
                }
              }

              buttons {
                text
                link
              }

              pdfs {
                id
                name
                pdfFile {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }

              pdfs2 {
                id
                name
                pdfFile {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }

              pdfsAlignment
              pdfs2Alignment
            }

            calculator {
              ...PageCalculatorFromCollection
            }

            # End of content
          }
        }
          
      }

      ...WebCalculator

      ...FullOffers

    }
  }
`;

export default ElectricityBonusPage;
